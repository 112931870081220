.troo_da_pricing_wrapper {
  padding-top: 102px;
  padding-bottom: 100px;
}
.troo_da_pricing_wrapper .row {
  align-items: center;
}
.troo_da_pricing_wrapper .troo_da_about_we_r_done_btn {
  padding: 0;
}
.pricing_title_content {
  max-width: 446px;
  width: 100%;
  position: relative;
  margin-left: 109px;
}
.pricing_title_content::after {
  content: "";
  background: url("../../images/pricing_humbergur.png");
  width: 80px;
  height: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: -27px;
  left: 75px;
}
.troo_da_pricing_wrapper button.btn.btn-primary {
  padding: 17px 45px 17px 45px;
}
.pricing_box {
  margin-right: 15px;
  margin-left: 15px;
  background: #ffffff;
  box-shadow: 0px 7px 15px rgb(85 85 85 / 15%);
  border-radius: 10px;
  max-width: 387px;
  width: 100%;
  padding: 41px 0 53px 0;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  margin-top: 39px;
}
.pricing_outer {
  margin-top: 39px;
}
.pricing_title h3 {
  color: #022539;
}
.pricing_title p {
  margin: 0;
  margin-top: 10px;
}
.pricing_tag_outer p {
  margin: 0;
}
.pricing_tag_outer p {
  font-size: 40px;
  color: #022539;
  font-weight: 700;
  padding-left: 6px;
  line-height: 1.45;
}
span.price_month {
  padding-left: 11px;
}
.pricing_tag_outer {
  padding-top: 10px;
}
.pricing_list {
  padding-top: 10px;
}
ul.pricing_list_ul li::before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 100%;
  position: absolute;
  top: 4px;
  left: 0;
  border: 2px solid #022539;
}
ul.pricing_list_ul li {
  position: relative;
  padding-left: 24px;
  padding-bottom: 17px;
}
ul.pricing_list_ul li:last-child {
  padding-bottom: 0;
}
.pricing_btn button.btn.btn-primary {
  width: 100%;
  max-width: 100%;
  background: #022539;
  box-shadow: 0px 7px 29px rgb(85 85 85 / 32%);
  border-radius: 10px;
  color: #fff;
}
.pricing_box.hover_box .pricing_btn button.btn.btn-primary {
  background-color: #fb991c;
  color: #022539;
  border: 1px solid #fb991c;
}
.pricing_btn {
  padding-top: 29px;
}
.pricing_box.hover_box {
  background-color: #022539;
}
.pricing_box.hover_box .pricing_title h3 {
  color: #fb991c;
}
.pricing_box.hover_box .pricing_tag_outer p {
  color: #fb991c;
}
.pricing_box.hover_box .pricing_tag_outer h4 {
  color: #fb991c;
}
.pricing_box.hover_box .pricing_title p {
  color: #fff;
}
.pricing_box.hover_box span.price_month {
  color: #fff;
}
.pricing_box.hover_box ul.pricing_list_ul li {
  color: #fff;
}
.pricing_box.hover_box ul.pricing_list_ul li::before {
  border: 2px solid #fff;
}
.hover_box_txt p {
  color: #022539;
  font-weight: 700;
  text-align: center;
  padding-top: 15px;
}
.hover_box_txt {
  background: #fb991c;
  max-width: 100%;
  width: 100%;
  height: 56px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.hover_box_txt {
  position: absolute;
  bottom: 20px;
  left: 0;
  z-index: 1;
}
.hover_box_add {
  position: relative;
}

.pricing_box:hover .hover_box_txt {
  opacity: 1;
}
.pricing_inner {
  padding-left: 30px;
  padding-right: 30px;
}
.troo_da_pricing_wrapper .row {
  margin: 0 -15px !important;
}
/* .priciing_title_outer {
  padding-left: 15px;
} */
.pricing_list ul{
  padding-left: 0;
}

@media (max-width: 1290px) {
  .pricing_box {
    margin: 0 auto;
    margin-top: 39px;
  }
}

@media (max-width: 1230px) {
  .pricing_box {
    max-width: 330px;
  }
  .troo_da_pricing_wrapper .row {
    margin: 0 0 !important;
  }
}

@media (max-width: 1100px) {
  .pricing_title_content {
    margin-left: 65px;
  }
}

@media (max-width: 1090px) {
  .pricing_box {
    max-width: 100%;
  }

  .pricing_title_content {
    margin-left: 60px;
  }
}
@media (max-width: 1050px) {
  .pricing_box {
    max-width: 100%;
    padding: 31px 0 41px 0;
  }
  .troo_da_pricing_wrapper{
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .pricing_box.hover_box{
    margin-top: 80px;
  }
  .troo_da_pricing_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pricing_title_content {
    margin-left: 0;
    margin: 0 auto;
    padding-top: 15px;
    max-width: 100%;
  }
  .troo_da_pricing_wrapper .troo_da_about_we_r_done_btn {
    padding: 0;
    text-align: center !important;
    margin-top: 30px;
    margin-bottom: 25px;
  }

  .priciing_title_outer {
    padding-left: 0;
  }
}


@media(max-width:450px){
  .troo_da_pricing_wrapper .troo_da_about_we_r_done_btn { margin-bottom: 10px; } 
}