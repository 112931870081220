@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&display=swap");
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.45 !important;
  font-weight: 400 !important;
  font-family: "Jost", sans-serif !important;
  background-color: #ffffff;
  color: #2d2d2d;
}
img {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: middle;
}
p {
  margin: 0;
}
* + h1,
* + h2 {
  margin-top: 25px;
}
* + h3,
* + h4 {
  margin-top: 20px;
}
* + h5,
* + h6 {
  margin-top: 22px;
}
* + p {
  margin-top: 20px;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1.45 !important;
  font-weight: 700 !important;
}
h1,
.h1 {
  font-size: 50px !important;
}
h2,
.h2 {
  font-size: 36px !important;
}
h3,
.h3 {
  font-size: 24px !important;
}
h4,
.h4 {
  font-size: 20px !important;
}

a {
  text-decoration: none;
  color: #022539;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
}
a:hover {
  color: #fb991c;
}
button.btn.btn-primary {
  padding: 17px 24px 17px 23px;
  background-color: #fb991c;
  color: #022539;
  max-width: 100%;
  font-size: 16px;
  line-height: 1.22;
  display: inline-block;
  font-weight: 700;
  border: 1px solid transparent;
  font-family: "Jost", sans-serif;
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
}
button.btn.btn-primary:hover {
  background: transparent;
  box-shadow: none;
  border: 1px solid #fb991c;
  color: #fb991c;
}
.container {
  max-width: 1262px !important;
  width: 100% !important;
  margin: 0 auto;
  padding: 0 30px;
}

@media (max-width: 991px) {
  h1,
  .h1 {
    font-size: 35px !important;
    line-height: 1.3 !important;
  }
  h2,
  .h2 {
    font-size: 30px !important;
    line-height: 1.2 !important;
  }
  h3,
  .h3 {
    font-size: 21px;
  }
}

@media (max-width: 630px) {
  h1,
  .h1 {
    font-size: 32px !important;
    line-height: 1.1;
  }
  h4,.h4{
    font-size: 18px !important;
  }
}
