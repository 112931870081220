.service_box_wrapper {
  padding-top: 100px;
  padding-bottom: 89px;
  background: #f4f9fd;
}
.servive_box {
  background: #ffffff;
  box-shadow: 0px 7px 15px rgb(85 85 85 / 15%);
  border-radius: 10px;
  border: 10px solid #fff;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-bottom: 30px;
  width: 100%;
}
.service_box_img_outer {
  width: 272px;
}
.service_box_img_outer img {
  max-width: 241px;
  width: 100%;
  height: 232px;
}
.service_box_detail {
  padding-left: 15px;
}
.service_box_img {
  min-width: 170px;
}
.service_box_title {
  position: relative;
  padding-top: 48px;
}
.service_box_title::after {
  content: "";
  width: 30px;
  height: 5px;
  background-color: #022539;
  position: absolute;
  top: 26px;
  left: 0;
  right: 0;
  border-radius: 100px;
}
.service_box_content {
  max-width: 275px;
  width: 100%;
  padding-top: 17px;
}
.servive_box_img_arrow {
  padding-left: 5px;
  margin-top: -4px;
}
.service_box_readmore {
  padding-top: 17px;
}
.service_box_readmore span {
  font-weight: 700;
  color: #022539;
}
.servive_box:hover {
  background: #022539;
  border: 10px solid #022539;
}
.servive_box:hover .service_box_title h4 {
  color: #fb991c;
}
.servive_box:hover .service_box_readmore span {
  color: #fb991c;
}
.servive_box:hover .service_box_content p {
  color: #fff;
}
.service_box_yellow_icon img {
  opacity: 0;
}
.servive_box:hover .service_box_yellow_icon img {
  opacity: 1;
}
.servive_box_blue_icon img {
  opacity: 1;
}
.servive_box:hover .servive_box_blue_icon img {
  opacity: 0;
}
.service_box_yellow_icon img {
  margin-top: -43px;
}
.servive_box:hover .service_box_title::after {
  background-color: #fb991c;
}
.service_box_wrapper .row {
  margin: 0 -15px !important;
}
.service_box_wrapper .troo_da_hand_we_r_done_title_outer {
  max-width: 445px;
}

@media (max-width: 1230px) {
  .service_box_wrapper .row {
    margin: 0 0 !important;
  }
}

@media (max-width: 575px) {
  .service_box_detail {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 0;
  }
  .service_box_img_outer {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  .service_box_content {
    padding-top: 5px;
    margin: 0 auto;
  }
  .service_box_readmore {
    justify-content: center;
    padding-top: 10px;
  }
  .service_box_title::after {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .servive_box {
    flex-direction: column;
    margin: 0;
    margin-bottom: 20px;
  }
}
