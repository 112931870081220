.our_team_title {
  max-width: 412px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 50px;
}
.our_team_wrapper {
  padding-top: 98px;
  padding-bottom: 100px;
}
.our_team_Detail_box {
  background: #ffffff;
  box-shadow: 0px 7px 15px rgb(85 85 85 / 15%);
  border-radius: 10px;
  max-width: 236px;
  width: 100%;
  margin: 0 auto;
  margin-top: -31px;
  z-index: 1;
  position: relative;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 14px;
}
.our_team_wrapper .top_header_list li a svg:hover {
  border-radius: 8px 0px;
}
.our_team_hoverBox {
  width: 90%;
  background: #022539;
  box-shadow: 0px 7px 15px rgb(85 85 85 / 15%);
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  height: 90%;
  text-align: center;
  padding: 47px 28px 46px 27px;
  opacity: 0;
  transition: all 0.3s ease-in;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.our_team_box {
  position: relative;
  max-width: 262px;
  width: 100%;
  height: 328px;
}
.our_team_hoverBox h4 {
  color: #fb991c;
}
.our_team_hoverBox p {
  margin: 0;
  color: #ffffff;
  margin-top: 6px;
}
.our_team_wrapper ul.top_header_list.d-flex {
  justify-content: center;
  padding-top: 8px;
  padding-left: 0;
}
.our_team_wrapper .top_header_list li a svg {
  color: #ffffff;
}

.our_team_wrapper
  .troo_da_hand_we_r_done_box:hover
  .troo_da_hand_we_r_done_box_img::after {
  display: none;
}
.our_team_wrapper .troo_da_hand_we_r_done_box_inner {
  max-width: 283px;
  width: 100%;
  height: 328px;
}
.our_team_wrapper .troo_da_hand_we_r_done_box_title {
  max-width: 236px;
  left: 15px;
}
.our_team_wrapper .troo_da_hand_we_r_done_box_inner {
  position: relative;
  transition: all 0.3s ease-in;
  max-width: 386px;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  background: transparent;
  border: 10px solid #fff;
}
.our_team_wrapper .troo_da_hand_we_r_done_box_inner img {
  height: 302px;
  object-fit: cover;
}
.our_team_wrapper .troo_da_hand_we_r_done_box {
  margin: 15px;
  background: #ffffff;
  box-shadow: 0px 7px 15px rgb(85 85 85 / 15%);
  border-radius: 10px;
  max-width: 282px;
  width: 100%;
  height: 328px;
}
.our_team_wrapper .row {
  margin: 0 -15px !important;
}
.our_team_wrapper .troo_da_about_we_r_done_btn button.btn.btn-primary {
  padding: 17px 41px 17px 41px;
}
.our_team_wrapper .troo_da_about_we_r_done_btn {
  padding-top: 50px;
}

@media (max-width: 1230px) {
  .our_team_wrapper .row {
    margin: 0 0 !important;
  }
  .our_team_wrapper .troo_da_hand_we_r_done_box {
    max-width: 257px;
  }

  .our_team_wrapper .troo_da_hand_we_r_done_box_title {
    max-width: 216px;
    left: 15px;
  }
}

@media (max-width: 1130px) {
  .our_team_wrapper .troo_da_hand_we_r_done_box {
    max-width: 223px;
  }
  .our_team_wrapper .troo_da_hand_we_r_done_box_title {
    max-width: 182px;
    left: 13px;
  }
 
}

@media (max-width:1024px){
  .our_team_wrapper{
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .our_team_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .our_team_wrapper .troo_da_hand_we_r_done_box_inner{
    max-width: 100%;
    height: auto;
  }
  .our_team_wrapper .troo_da_hand_we_r_done_box_inner img{
    width: 100%;
    height: auto;
    object-fit: unset;
  }

  .our_team_wrapper .troo_da_hand_we_r_done_box {
    max-width: 100%;
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .our_team_wrapper .troo_da_hand_we_r_done_box_title {
    max-width: 75%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .our_team_title {
    padding-bottom: 25px;
  }
  .our_team_wrapper .troo_da_about_we_r_done_btn {
    padding-top: 25px;
  }
}
