.Blog_detail_wrapper {
  padding-top: 100px;
  padding-bottom: 41px;
}
.blog_top_detail {
  position: relative;
}
.blog_top_detail::after {
  content: "";
  max-width: 1042px;
  width: 100%;
  background: #d9d9d9;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}
.blog_top_detail::before {
  content: "";
  max-width: 1042px;
  width: 100%;
  background: #d9d9d9;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.blog_top_detail p {
  padding: 13px 0;
}
.blog_top_detail {
  max-width: 1042px;
  width: 100%;
  margin-bottom: 40px;
}
.blog_detail_title h2 {
  padding-bottom: 17px;
}
.blog_detail_title img {
  max-width: 1027px;
  width: 100%;
}
.blog_detail_title {
  padding-bottom: 18px;
  max-width: 1000px;
  width: 100%;
}
.blog_detail_right_content {
  margin-left: 30px;
  max-width: 491px;
  width: 100%;
}
.blog_detail_right_content p + p {
  margin-top: 25px;
}
.blog_detail_img img {
  max-width: 506px;
  width: 100%;
}
.blog_detail_cont {
  padding-top: 27px;
  padding-bottom: 19px;
  max-width: 1000px;
  width: 100%;
}
ul.blog_detail_benifits_ul li::after {
  content: "";
  width: 15px;
  height: 15px;
  border: 2px solid #fb991c;
  border-radius: 100%;
  position: absolute;
  top: 5px;
  left: 0;
}
ul.blog_detail_benifits_ul li {
  position: relative;
  padding-left: 20px;
}
.blog_detail_benifits_title {
  padding-bottom: 11px;
}
.blog_detail_bottom_cont {
  padding-top: 19px;
  padding-bottom: 18px;
  max-width: 1000px;
  width: 100%;
}
.blog_detail_bottom_cont p + p {
  margin-top: 19px;
}
ul.blog_detail_benifits_ul {
  width: 100%;
  padding-left: 0;
}
.blog_detail_social_icon span {
  font-weight: 700;
}
.blog_detail_social_icon .top_header_list {
  padding-left: 17px;
}
.blog_detail_social_icon .top_header_list li i:hover {
  border-radius: 5px;
}
.blog_detail_social_icon .troo_da_top_header_social_icon {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  max-width: 1000px;
  width: 100%;
  padding: 13px 0;
}
.blog_detail_social_icon h3 {
  margin-top: 20px;
}
.blog_detail_social_icon p {
  margin-top: 13px;
}
.blog_detail_form {
  padding-top: 24px;
  max-width: 717px;
  width: 100%;
}
.blog_detail_form .form-control {
  background: #f4f9fd;
  border-radius: 10px;
  border: none;
  max-width: 717px;
  width: 100%;
  height: 60px;
  padding: 0;
  padding-left: 19px;
}
.blog_detail_form .form-group {
  margin-bottom: 30px;
}
.blog_detail_form textarea.form-control {
  min-height: 145px;
  padding-top: 21px !important;
  resize: none;
}
/* CheckBox Custom */
.blog_detail_form .form-check-input[type="checkbox"] {
  background: #fff;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.blog_detail_form .form-check-input:focus {
  border-color: #022539;
  outline: 0;
  box-shadow: 0px 7px 29px rgb(252 117 35 / 30%);
}
.blog_detail_form .form-check-input:checked[type="checkbox"] {
  background: #022539 url("../../images/check.svg");
}
/* CheckBox Custom */
.blog_detail_form .form_submit button.btn.btn-primary {
  width: auto;
  padding: 17px 19px 17px 18px;
}
.form-check label {
  font-size: 14px;
  line-height: 1.445;
  color: #777777;
  padding-left: 5px;
  padding-top: 4px;
}
.form-check {
  margin: 0;
  margin-bottom: 35px;
}
.read_ur_latest_blox_wrapper.blog_detail_latest {
  background: transparent;
  padding-top: 0;
  padding-bottom: 0;
}
.read_ur_latest_blox_wrapper.blog_detail_latest
  .troo_da_about_hero_handyman_title {
  padding-top: 0;
  padding-left: 14px;
  padding-bottom: 18px;
}

.read_ur_latest_blox_wrapper.blog_detail_latest .row {
  max-width: 1043px;
  width: 100%;
  margin: 0 -15px !important;
}
.read_ur_latest_blox_wrapper.blog_detail_latest
  .troo_da_hand_we_r_done_box_img
  img {
  max-width: 329px;
  width: 100%;
  height: 320px;
  object-fit: cover;
}
.read_ur_latest_blox_wrapper.blog_detail_latest
  .troo_da_hand_we_r_done_box_title {
  max-width: 254px;
  width: 100%;
  margin: 0 auto;
}

.read_ur_latest_blox_wrapper.blog_detail_latest
  .troo_da_hand_we_r_done_box_title
  h4 {
  max-width: 221px;
  width: 100%;
  margin: 0 auto;
}
.read_ur_latest_blox_wrapper.blog_detail_latest .troo_da_hand_we_r_done_box {
  margin: 0;
  margin-top: 31px;
  margin: 15px;
}
.brand_logo_wrapper.blog_detail_brand {
  padding-top: 68px;
}
@media (max-width: 1290px) {
  .troo_da_hand_we_r_done_box_title h3 {
    font-size: 18px;
  }
  .troo_da_hand_we_r_done_box_inner {
    max-width: 100%;
    margin-bottom: 30px;
  }
  .troo_da_hand_we_r_done_box_title {
    max-width: 75%;
  }
  /* .troo_da_hand_we_r_done_box_hover_detail {
    max-width: 257px;
    top: 12px;
    left: 0;
    height: 282px;
    padding: 14px 28px 23px 27px;
  } */
  .troo_da_hand_we_r_done_box_hover_detail::after {
    top: 20px;
  }
  .troo_da_hand_we_r_done_box_hover_detail p {
    margin-top: 5px;
  }
}
@media (max-width: 991px) {
  .brand_logo_wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .Blog_detail_wrapper {
    padding-top: 50px;
  }
  .blog_top_detail {
    margin-bottom: 15px;
  }
  .blog_detail_title h3 + p {
    margin-top: 15px;
  }
  .blog_top_detail p {
    text-align: center;
  }
  .blog_detail_title h2 {
    padding-bottom: 17px;
    text-align: center;
  }
  .blog_detail_title {
    text-align: center;
  }
  .blog_detail_img {
    text-align: center;
  }
  .blog_detail_right_content {
    margin-left: 0;
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 15px;
  }
  .blog_detail_right_content p + p {
    margin-top: 10px;
  }
  .blog_detail_cont {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .blog_detail_benifits_title {
    text-align: center;
  }

  ul.blog_detail_benifits_ul li {
    padding-bottom: 10px;
  }
  .blog_detail_bottom_cont {
    padding-top: 5px;
  }
  .blog_detail_bottom_cont {
    text-align: center;
  }
  .blog_detail_bottom_cont p + p {
    margin-top: 10px;
  }
  .troo_da_top_header_inner {
    justify-content: center;
  }
  .blog_detail_social_icon h3 {
    margin-top: 10px;
    text-align: center;
  }
  .blog_detail_social_icon p {
    margin-top: 5px;
    text-align: center;
  }
  .blog_detail_form {
    margin: 0 auto;
  }
  .form-check {
    max-width: 500px;
    width: 100%;
  }
  .blog_detail_form .form-group {
    margin-bottom: 20px;
  }
  .blog_detail_form .form_submit button.btn.btn-primary {
    width: 100%;
  }
  .read_ur_latest_blox_wrapper.blog_detail_latest
    .troo_da_hand_we_r_done_box_title
    h4 {
    font-size: 16px;
  }
}

@media (max-width: 630px) {
  .troo_da_hand_we_r_done_box_title h3 {
    font-size: 18px !important;
  }
  .troo_da_hand_we_r_done_box_title {
    padding: 10px 10px;
    margin: 0 auto;
  }
}
