.coming_soon_wrapper {
  background: #022539;
  padding-top: 251px;
  padding-bottom: 283px;
  position: relative;
}
.coming_soon_title h1 {
  color: #fff;
}
.coming_soon_title span {
  color: #fb991c;
}
.coming_soon_title {
  padding-top: 30px;
}
.cooming_soon_detail .footer_form {
  max-width: 500px;
  width: 100%;
}
.cooming_soon_detail .footer_form input.form-control {
  max-width: 500px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(149, 157, 165, 0.2);
  border-radius: 10px;
}
.cooming_soon_detail button.btn.btn-primary {
  background: #022539;
  color: #fff;
  max-width: 100px;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  padding: 0 24px 0 23px;
}
.coming_soon_wrapper .choose_people_vector_5 {
  position: absolute;
  top: 160px;
  right: 52px;
}
.coming_soon_wrapper .choose_people_vector_1 {
  position: absolute;
  top: 79px;
  left: 0;
  opacity: 0.1;
}
.coming_soon_wrapper .choose_people_vector_4 {
  position: absolute;
  bottom: 73px;
  right: 128px;
}
.coming_soon_wrapper .choose_people_vector_3 {
  position: absolute;
  top: 83%;
  left: 51%;
  transform: translate(-50%, -50%);
}
.cooming_soon_detail {
  margin-left: 12px;
}
.coming_soon_title p {
  color: #fff;
  margin-top: 40px;
  max-width: 433px;
  width: 100%;
  font-weight: 500;
}

@media (max-width: 1230px) {
  .cooming_soon_detail {
    margin-left: 25px;
  }
}

@media (max-width: 991px) {
  .choose_people_vector_2 {
    display: none;
  }
  .choose_people_vector_5 {
    display: none;
  }
  .choose_people_vector_4 {
    display: none;
  }
  .choose_people_vector_3 {
    display: none;
  }
  .choose_people_vector_7 {
    display: none;
  }
  .choose_people_vector_6 {
    display: none;
  }
  .choose_people_vector_6 {
    display: none;
  }
  .coming_soon_wrapper {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .comin_soon_img {
    margin: 0 auto;
  }
  .cooming_soon_detail {
    margin-left: 0;
    margin-top: 30px;
  }
  .coming_soon_title {
    text-align: center;
  }
  .coming_soon_title p {
    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
  }
  .cooming_soon_detail .footer_form {
    margin: 0 auto;
  }
  .cooming_soon_detail button.btn.btn-primary {
    background-color: #fb991c;
    text-align: center;
  }
  .coming_soon_wrapper .footer_form_submit_btn {
    margin-left: 0;
    margin-top: 0;
  }
}
@media (max-width: 630px) {
  .coming_soon_title h1 {
    font-size: 25px;
  }
  .coming_soon_title {
    padding-top: 15px;
  }
}
