.brand_logo img {
  max-width: 170px;
  width: 100%;
  height: 75px;
  margin: 0 auto;
}
.brand_logo_wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}
.brand_logo_outer.d-flex{
    column-gap: 35px;
    justify-content: center;
}
@media (max-width : 767px){
  .brand_logo_outer.d-flex{
    column-gap: 0;
  }
}
@media (max-width : 550px){
  .brand_logo_outer.d-flex{
  flex-direction: column;
  }
  .brand_logo_outer.d-flex .item{
    display: flex;
    justify-content: center;
  }
}