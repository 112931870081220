.project_detail_wrapper {
  padding-top: 100px;
  padding-bottom: 95px;
}
.project_detail_wrapper a{
  text-decoration: none;
 
}
.service_box_wrapper_right_side.project_left_side {
  margin-left: 0;
  margin-right: 36px;
}
.project_title h2 {
  color: #022539;
}
.project_sub_title h4 {
  font-weight: 500;
  color: #022539;
}
.project_sub_title p {
  margin-top: 12px;
}
.project_sub_title {
  padding-top: 8px;
  padding-bottom: 31px;
}
.project_gallary_box img {
  border-radius: 10px;
}
.project_gallary_box {
  margin: 15px;
}
.project_third_box img {
  border-radius: 10px;
}
.project_third_box {
  margin-top: 30px;
}
.project_right_box .row {
  margin: 0 -8px !important;
}
.project_third_box {
  margin: 15px;
  position: relative;
}
.project_box_hover {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}
.project_third_box::after {
  content: "";
  background: #1f1e23;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  border-radius: 10px;
}
.project_gallary_box {
  position: relative;
}
.project_gallary_box::after {
  content: "";
  background: #1f1e23;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  border-radius: 10px;
}
.project_gallary_box .project_box_hover {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}
.project_box_hover img {
  max-width: 90px;
  width: 100%;
  margin: 0 auto;
}
.troo_da_hand_we_r_done_wrapper a {
  color: #2d2d2d;
}
.troo_da_hand_we_r_done_wrapper a:hover {
  color: inherit;
}
@media (max-width: 1230px) {
  .troo_da_hand_we_r_done_wrapper .row {
    margin: 0 0 !important;
  }
  .project_right_box .row {
    margin: 0 0 !important;
  }
}
@media (max-width: 991px) {
  .project_detail_wrapper .row {
    flex-direction: column-reverse;
  }
  .project_right_box {
    text-align: center;
  }
  .project_gallary_box {
    position: relative;
    display: inline-block;
  }
  .project_third_box {
    display: inline-block;
  }
  .project_sub_title {
    padding-bottom: 10px;
  }
  .play_btn img { max-width: 70px; width: 100%; height: 100%; object-fit: cover; }
}
