.hero_reapeat_wrapper {
  background: url("../../images/hero_reapeat_bg.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
  padding: 108px 0;
  z-index: 1;
  position: relative;
}
.hero_repeat_title h2 {
  color: #fff;
}
ol.breadcrumb li a {
  color: #fff;
}
.breadcrumb-item.active {
  color: #fff;
}
.breadcrumb {
  margin-bottom: 18px;
}
.about_year_box {
  background: #022539;
  border: 5px solid #ffffff;
  box-shadow: 0px 7px 15px rgb(85 85 85 / 15%);
  border-radius: 10px;
  max-width: 333px;
  width: 100%;
  padding: 25px 21px 24px 20px;
  position: absolute;
  bottom: 34px;
  right: 53px;
  padding-left: 15px;
}
.about_year_box_outer {
  position: relative;
}
.about_yr_icon {
  min-width: 63px;
}
.about_yr_detail h2 {
  margin: 0;
  color: #fb991c;
}
.about_yr_detail h4 {
  color: #ffffff;
}
.about_yr_detail {
  margin-left: 13px;
}
.breadcrumb-item a {
  text-decoration: none;
}
.breadcrumb-item.active a {
  color: #fb991c !important;
}

@media (max-width: 991px) {
  .hero_reapeat_wrapper {
    padding: 50px 0;
  }
  .about_year_box{
    max-width: 100%;
    bottom: inherit;
    right: inherit
  }
}
