.service_detail_wrapper {
  padding-top: 108px;
}
.servive_detail_wrapper_Box_content {
  max-width: 610px;
  width: 100%;
}
.service_box_wrapper_img {
  padding-top: 60px;
  padding-bottom: 37px;
}
.service_box_wrapper_img_outer {
  position: relative;
}
.play_btn {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.service_detail_wrapper .troo_da_people_choose_list {
  background: #f4f9fd;
  padding: 27px 10px 25px 30px;
  border-radius: 10px;
  margin-top: 19px;
  margin-bottom: 14px;
}
.service_detail_wrapper .troo_da_people_choose_list_ul p {
  color: #2d2d2d;
}
.service_detail_wrapper .troo_da_people_chooseList_box_2 {
  width: calc(100% - 265px);
  margin-right: auto;
}
.service_detail_wrapper .troo_da_people_chooseList_box {
  width: 265px;
}
.service_detail_wrapper .troo_da_people_choose_list h4 {
  color: #2d2d2d;
}
.service_box_wrapper_left_side h4 {
  color: #022539;
  font-weight: 500;
}
.service_box_wrapper_left_side h4 + p {
  margin-top: 12px;
}
.service_box_wrapper_left_side p {
  margin-top: 0;
}
.service_box_wrapper_left_side p + p {
  margin-top: 12px;
  margin-bottom: 23px;
}
.latest_tech_box_img img {
  border-radius: 10px;
}
.latest_box_list_ul li {
  position: relative;
  padding-left: 20px;
  padding-bottom: 10px;
}
.latest_box_list_ul li::before {
  content: "";
  width: 15px;
  height: 15px;
  border: 2px solid #fb991c;
  border-radius: 100%;
  position: absolute;
  top: 5px;
  left: 0;
}
.latest_tech_box h4 {
  font-weight: 700;
}
.latest_tech_box h4 + p {
  margin-top: 10px;
}
ul.latest_box_list_ul {
  padding-top: 10px;
}
.latest_tech_box_img {
  margin-top: 13px;
}
.latest_tech_box_img img {
  width: 100%;
  height: 100%;
}
.other_list {
  background: #f4f9fd;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 22px 14px 28px 30px;
}
.other_list .footer_title h4 {
  color: #022539;
  font-weight: 700;
}
ul.other_list_ul {
  padding-top: 25px;
  padding-left: 0
}
ul.other_list_ul li {
  padding-bottom: 27px;
  position: relative;
  padding-left: 12px;
  cursor: pointer;
}
ul.other_list_ul li::before {
  content: "";
  background: transparent;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 8px;
  left: 0;
  border-radius: 100%;
  transition: all 0.3s ease;
}
ul.other_list_ul li:hover::before {
  content: "";
  background: #022539;
  color: #022539;
}
.need_any_help_box {
  background: #022539;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 10px;
  /* max-width: 282px; */
  width: 100%;
  height: 100%;
  padding: 22px 10px 30px 30px;
  overflow: hidden;
  position: relative;
}
.need_any_help_box::after {
  content: "";
  background: url("../../images/need_any_help_bg_1.png");
  width: 125px;
  height: 134px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 68px;
  right: -27px;
}
.need_any_help_box::before {
  content: "";
  background: url("../../images/need_any_help_bg_2.png");
  width: 45px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 54px;
  left: -27px;
}
.need_any_help_box .footer_call {
  margin-left: 0;
}
.need_any_help_box .footer_call_detail {
  padding-top: 34px;
}
.need_any_help_box .footer_call_detail p {
  font-weight: 700;
}
.need_any_help_box .footer_mail_detail p {
  font-weight: 700;
}

.need_any_help_box .footer_mail_detail {
  padding-bottom: 10px;
}
.need_any_help_box .footer_call {
  padding-bottom: 24px;
}
.other_box {
  padding-bottom: 31px;
}
.service_box_wrapper_right_side {
  margin-left: 30px;
}
.troo_da_people_choose_list .troo_da_people_chooseList_box p + p {
  margin-bottom: 0;
  margin-top: 0;
}
.troo_da_people_choose_list.our_feature .troo_da_people_chooseList_box_2 p + p {
  margin: 0;
}
.service_box_wrapper a {
  color: #022539;
  text-decoration: none;
}
.service_box_wrapper a:hover {
  color: inherit;
}
.latest_box_list ul{
  padding-left: 0;
}

@media (max-width: 1130px) {
  .other_list {
    padding: 22px 14px 23px 23px;
  }

}
@media (max-width: 991px) {
  .latest_tech_box h4 + p {
    text-align: center;
  }
  .service_box_wrapper_right_side {
    margin-left: 0px;
  }
  .latest_tech_box_img {
    text-align: center;
  }
  .other_list {
    margin: 0 auto;
    margin-top: 30px;
    
  }
  .need_any_help_box {
    margin: 0 auto;
  }
  .service_detail_wrapper .servive_detail_wrapper_Box_content {
    text-align: center;
    margin: 0 auto;
  }
  .service_detail_wrapper {
    padding-top: 50px;
  }
  .service_box_wrapper_img {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .service_detail_wrapper .troo_da_people_chooseList_box_2 {
    width: 100% !important;
  }
  .service_detail_wrapper .troo_da_people_chooseList_box {
    width: 100%;
  }
  .latest_box_list {
    width: 100%;
    margin: 0 auto;
  }
  .service_box_wrapper_right_side.project_left_side {
    margin-left: 0;
    margin-right: 0;
  }
}
