.contact_us_wrapper {
  padding-top: 135px;
  padding-bottom: 125px;
}
.contct_title {
  padding-bottom: 17px;
}
address.contct_address {
  color: #202020;
  max-width: 205px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 12px;
}
.contct_us_box {
  max-width: 356px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.conct_box_detail {
  padding-top: 12px;
}
.contct_box_number {
  padding-top: 12px;
}
.conct_box_detail a {
  color: inherit;
  text-decoration: none;
}
.conct_box_detail a:hover {
  color: #022539;
}
.contct_timing {
  padding-top: 12px;
}
.contct_timing p + p {
  margin-top: 7px;
}
.contct_us_box.contct_box2 {
  position: relative;
}
.contct_us_box.contct_box2::after {
  content: "";
  background: #fb991c;
  opacity: 0.1;
  max-width: 318px;
  width: 100%;
  height: 317px;
  position: absolute;
  top: -146px;
  left: 0;
  border-radius: 100%;
  right: 0;
  margin: 0 auto;
}
.contct_title h2 {
  color: #2d2d2d;
}
.conct_box_detail h4 {
  color: #022539;
}
.contact_form_box {
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 10px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  max-width: 625px;
  width: 100%;
  padding: 30px;
  height: 547px;
}
.contact_form_box .blog_detail_form {
  padding-top: 0;
}
.contact_form_box .input-group {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}
.form-group .fa-calendar:before {
  content: "\f133";
}
.contact_form_box span.input-group-text {
  background: #f4f9fd;
  height: 50px;
}
.contact_form_box .blog_detail_form .form-group {
  margin-bottom: 15px;
}
.contact_form_box .form_submit {
  padding-top: 2px;
}
.contact_map_wrapper h4 {
  padding-bottom: 36px;
}
select {
  color: #777777 !important;
}
.contct_form_map {
  max-width: 565px;
  width: 100%;
  height: 547px;
  margin-left: 30px;
}
.contact_map_wrapper {
  padding-bottom: 87px;
}
.contact_form_box .blog_detail_form .form-control {
  max-width: 565px;
  height: 50px;
}
.contact_form_box .blog_detail_form textarea.form-control {
  min-height: 95px;
}
.contct_form_map iframe {
  height: 547px;
  max-width: 565px;
  width: 100%;
}
.book_an_appoimnet_wrapper .contact_form_box {
  max-width: 100%;
  padding: 50px;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.book_an_appoimnet_wrapper .blog_detail_form {
  max-width: 100%;
  width: 100%;
}
.book_an_appoimnet_wrapper {
  padding-top: 135px;
  padding-bottom: 100px;
}
.book_an_appoinment_title {
  padding-bottom: 44px;
  position: relative;
}
.book_an_appoinment_title::after {
  content: "";
  background: #fb991c;
  opacity: 0.1;
  max-width: 318px;
  width: 100%;
  height: 317px;
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 100%;
}
.book_an_appoimnet_wrapper .contact_form_box .blog_detail_form .form-control {
  max-width: 545px;
  height: 50px;
}
.book_an_appoimnet_wrapper .contact_form_box .input-group {
  max-width: 545px;
  width: 100%;
}
.book_an_appoimnet_wrapper textarea.form-control.text-2 {
  width: 100%;
  max-width: 100% !important;
}
.book_an_appoimnet_wrapper .form-group {
  margin-right: 15px;
  margin-left: 15px;
}
.book_an_appoimnet_wrapper form {
  margin: 0 -15px;
}
.book_an_appoimnet_wrapper
  .contact_form_box
  .blog_detail_form
  textarea.form-control {
  min-height: 131px;
}
.book_an_appoimnet_wrapper .contact_form_box .form_submit {
  padding-top: 2px;
  padding-left: 15px;
}
.contact_form_box .form-control:focus {
  box-shadow: none !important;
  background-color: #f4f9fd;
}

@media (max-width: 1230px) {
  .contct_form_map {
    max-width: 500px;
    width: 100%;
    height: 547px;
    margin-left: 16px;
  }
}

@media (max-width: 1090px) {
  .contct_form_map {
    max-width: 450px;
    width: 100%;
    height: 547px;
    margin-left: 15px;
  }
}
@media (max-width: 991px) {
  .contact_form_box {
    margin: 0 auto;
  }
  .contact_us_wrapper {
    padding-top: 50px;
    padding-bottom: 0;
  }
  .contact_map_wrapper h4 {
    padding-bottom: 15px;
    text-align: center;
  }
  .contact_map_wrapper h4 {
    padding-top: 15px;
  }
  .contct_form_map {
    max-width: 565px;
    margin-left: 0;
    margin: 0 auto;
  }
  .contct_us_box.contct_box2::after {
    display: none;
  }
  .contct_us_box {
    padding-bottom: 30px;
  }
  .book_an_appoimnet_wrapper .contact_form_box .blog_detail_form .form-control {
    max-width: 100%;
  }
  .book_an_appoimnet_wrapper .contact_form_box {
    max-width: 100%;
    padding: 25px;
  }
  .book_an_appoimnet_wrapper .contact_form_box .input-group {
    max-width: 100%;
    width: 100%;
  }
  .book_an_appoimnet_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .book_an_appoinment_title::after {
    display: none;
  }
  .book_an_appoinment_title {
    padding-bottom: 25px;
  }
}
